import React from "react";
import styles from "./SuccessAlert.module.css";

function SuccessAlert({ setPreferenceSuccess }) {
  return (
    <div>
      <div className={styles.subSuccess}>
        <div class={styles.centerAlign}>
          <svg width="63px" height="62px" viewBox="0 0 63 62" version="1.1">
            <defs></defs>
            <g
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
              transform="translate(-689.000000, -305.000000)">
              <g id="Success" transform="translate(689.000000, 305.000000)">
                <path
                  d="M31.6755612,60.095825 C15.7199946,60.095825 2.78339084,47.1413477 2.78339084,31.1559159 C2.78339084,15.1704841 15.7199946,2.21600682 31.6755612,2.21600682 C47.6247987,2.21600682 60.5614024,15.1704841 60.5614024,31.1559159 C60.5614024,47.1413477 47.6247987,60.095825 31.6755612,60.095825 Z"
                  id="Fill-8"
                  fill="#D5ECE4"></path>
                <path
                  d="M31.6723966,4.11764545 C16.7864416,4.11764545 4.67894506,16.2478045 4.67894506,31.1552818 C4.67894506,46.0691 16.7864416,58.1992591 31.6723966,58.1992591 C46.5583516,58.1992591 58.6658482,46.0691 58.6658482,31.1552818 C58.6658482,16.2478045 46.5583516,4.11764545 31.6723966,4.11764545 M31.6723966,61.9974636 C14.6915219,61.9974636 0.887836625,48.1616 0.887836625,31.1552818 C0.887836625,14.1489636 14.6915219,0.3131 31.6723966,0.3131 C48.6532713,0.3131 62.4569566,14.1489636 62.4569566,31.1552818 C62.4569566,48.1616 48.6532713,61.9974636 31.6723966,61.9974636"
                  id="Fill-10"
                  fill="#36B77C"></path>
                <polyline
                  id="Fill-12"
                  fill="#D5ECE4"
                  points="21.2148867 32.4596068 27.5566073 39.3331523 42.1387673 22.9799477"></polyline>
                <path
                  d="M27.5566073,41.2341568 C27.024966,41.2341568 26.5249701,41.012225 26.1578845,40.6254295 L19.8161638,33.7518841 C19.1073088,32.9782932 19.1579413,31.7735205 19.9237579,31.0633386 C20.7022326,30.3468159 21.8857673,30.3912023 22.6136095,31.1711341 L27.531291,36.5038386 L40.7210573,21.7168386 C41.4235832,20.924225 42.619776,20.8671568 43.4045798,21.5646568 C44.1830545,22.2621568 44.2526742,23.4669295 43.5501482,24.2468614 L28.9743173,40.6000659 C28.6072317,41.0058841 28.0249579,41.3039068 27.5566073,41.2341568"
                  id="Fill-14"
                  fill="#36B77C"></path>
              </g>
            </g>
          </svg>
          <h2 class={styles.primaryHeading}>
            Your preferences have been updated!
          </h2>
          <h4 class={styles.secondaryHeading}>
            You have successfully changed your preferences for{" "}
            <b>{sessionStorage.getItem("email")}</b>.If you did this by mistake,
            <br />
            click the button below to update your preferences. <br />
            <a
              class={styles.backPreferences}
              onClick={() => setPreferenceSuccess(false)}>
              back to preferences
            </a>
          </h4>
        </div>
      </div>
    </div>
  );
}

export default SuccessAlert;
